

.layout {
  display: flex;
  min-width: 640px;
  min-height: 100vh;
}
.head {
  display: flex;
  justify-content: space-between;
}
.headLeft {
  display: flex;
}
.content {
  margin: 0 50px;
}

.footer {
  text-align: center;
  color: silver;
}
.outlet {
  min-height: 50vh;
}

.logo {
  display: flex;
  width: 190px;
  align-items: center;
}
.logoTxt {
  background-color: white;
  border-radius: 5px;
  padding: 0 5px;
  margin: 0 5px;
}
.logo img {
  width: 50px;
  height: 50px;
}

.logo .title {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
.headRight {
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 100px;
}
.userInfo {
  display: flex;
  flex-direction: column;
  color: white;
  height: 100%;
  line-height: 20px;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
}
.userInfo:hover {
  background-color: rgba(120, 120, 120, 0.2);
}
.username {
  font-size: 14px;
}
.userTitle{
  font-size: 12px;
  color: #ddd;
}